import { template as template_e4b7f9b5d80440d4a2e0d6323d429980 } from "@ember/template-compiler";
const SidebarSectionMessage = template_e4b7f9b5d80440d4a2e0d6323d429980(`
  <div class="sidebar-section-message-wrapper sidebar-row">
    <div class="sidebar-section-message">
      {{yield}}
    </div>
  </div>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default SidebarSectionMessage;
