import { template as template_cf9b1b96867e4798b43a60b07c6320fd } from "@ember/template-compiler";
import DButton from "discourse/components/d-button";
const PostMenuShareButton = template_cf9b1b96867e4798b43a60b07c6320fd(`
  <DButton
    class="post-action-menu__share share"
    ...attributes
    @action={{@buttonActions.share}}
    @icon="d-post-share"
    @label={{if @showLabel "post.controls.share_action"}}
    @title="post.controls.share"
  />
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default PostMenuShareButton;
